import styled from 'styled-components';

export const Container = styled.div`
`;

export const Content = styled.div`
h1 {
    margin: 0 0 0.5rem 0;
    font-size: 1.2rem;
}
p {
    margin: 1rem 0;
    font-size: 0.9rem;
    line-height: 1.5;
}

// outline: 1px solid red;
`;
