import styled from 'styled-components';

export const Select = styled.select`
margin: 0 0.2vw;
padding: 1vh 1vw;
border: 0;
color: white;
background-color: rgba(255,255,255,0.1);
font-size: 1.2rem;
cursor: pointer;
option {
    color: white;
    background-color: rgba(0,0,0,0.5);
}
`;

export const Button = styled.button`
margin: 0 0.5vw;
padding: 1vh 1vw;
border: 1px solid white;
color: white;
background-color: transparent;
font-size: 1.2rem;
cursor: pointer;
`;